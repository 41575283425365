import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gameplay"
    }}>{`Gameplay`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "give-your-character-a-contract"
    }}>{`Give your character a contract`}</h3>
    <p>{`Before accepting a quest or doing any action, you need to sign your character a contract to work on farmland. If your character is a new NFT that has never signed a contract before, the first signing is cost-free.`}</p>
    <p><img alt="contract" src={require("./public/images/con-1.png")} /></p>
    <p><img alt="contract" src={require("./public/images/con-2.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`One farmland can have only one charact`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      